<template>
  <NForm
    inline
    label-placement="left"
  >
    <NFormItem label="搜索标题：">
      <NInput
        maxlength="50"
        show-count
        clearable
        v-model:value="formValue.title"
      />
    </NFormItem>
    <!--
    <NFormItem label="组卷方式：">
      <div style="width: 240px;">
        <NSelect
          :options="composePaperWayOptions"
          v-model:value="formValue.type"
        />
      </div>
    </NFormItem>
    -->
    <NFormItem>
      <NSpace>
        <NButton type="primary" @click="handleSearch">搜索</NButton>
        <NButton @click="handleClear">清除</NButton>
      </NSpace>
    </NFormItem>
  </NForm>
</template>

<script setup>
  import { reactive } from 'vue';

  import composePaperWayMap from '@/enumerators/compose-paper-way-map.js';

  const composePaperWayOptions = (() => Object.keys(composePaperWayMap).map(value => ({ label: composePaperWayMap[value], value })))();

  const getDefaultFormValue = () => ({
    title: '',
    type: null
  });
  const formValue = reactive(getDefaultFormValue());
  const getSearchParams = () => {
    const params = {};
    Object.keys(formValue).forEach(key => {
      const value = formValue[key];
      value && (params[key] = value);
    });
    return params;
  };

  const emit = defineEmits(['list-update']);
  const handleSearch = () => {
    emit('list-update');
  };
  const handleClear = () => {
    Object.assign(formValue, getDefaultFormValue());
    handleSearch();
  };

  defineExpose({
    getSearchParams
  });
</script>